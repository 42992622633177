import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Box, Heading } from "grommet"
import theme from "../themes/po3Theme.json"
import styled from "styled-components"

const JournalLink = styled(Link)`
  color: ${theme.global.colors.text.light};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
export default function JournalComponent() {
  const data = useStaticQuery(graphql`
    query JournalComponentQuery {
      allMdx(filter: { frontmatter: { type: { eq: "journal" } } }) {
        nodes {
          slug
          frontmatter {
            title
            author
            featured
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
      allFile(
        filter: { relativePath: { eq: "static/images/northern-lights.jpg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
`);
  let articles = data.allMdx.nodes

  return (
    <Box direction="row-responsive" fill gap="medium">
      {articles
        .filter(article => article.frontmatter.featured === true)
        .map((article, i) => {
          return (
            <Box basis="1/3" key={i}>
              <JournalLink to={`/${article.slug}`} key={i}>
                <Box margin={{ bottom: "medium" }}>
                  <Box height="small">
                    <Img
                      alt={article.frontmatter.title}
                      fluid={
                        article.frontmatter.image
                          ? article.frontmatter.image.childImageSharp.fluid
                          : data.allFile.edges[0].node.childImageSharp.fluid
                      }
                      objectFit="cover"
                      style={{ height: "300px" }}
                    />
                  </Box>
                  <Heading
                    color="black"
                    level={3}
                    size="small"
                    margin={{ bottom: "none" }}
                  >
                    {article.frontmatter.title}
                  </Heading>
                </Box>
              </JournalLink>
            </Box>
          )
        })}
    </Box>
  )
}
