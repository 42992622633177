import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box, Grid } from "grommet";

export default function Clients() {

  const data = useStaticQuery(graphql`
    query LogoQuery {
      allFile(filter: { relativeDirectory: { eq: "static/images/clients" } }) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);

  return (
    <Grid fill="horizontal" columns={["flex", "flex", "flex"]} gap="medium">
      {data.allFile.nodes.map((avatar,i) => {
        let image = getImage(avatar.childImageSharp.gatsbyImageData);
        return (
          <Box key={i}>
            <GatsbyImage  image={image} alt={`logo for ${avatar.name} `} />
          </Box>
        )
      })}
    </Grid>
  );
}
