import React from "react"
import { Link } from "gatsby"
import { Box, Button, Heading, Paragraph } from "grommet"

const ClientHire = () => {
  return (
    <Box gap="xlarge" justify="around" direction="row-responsive">
      <Box align="center" basis="1/3">
        <Heading
          textAlign="center"
          margin={{
            bottom: "xsmall",
          }}
          level="3"
          size="medium"
        >
        Got a problem that needs solving?
        </Heading>
        <Paragraph textAlign="center">
        We know how to solve complex problems - by co-creating solutions with you, your users and our global community of design experts.

        </Paragraph>
        <Link to="/contact">
          <Button size="medium" color="brand" label="Solve a problem" />
        </Link>
      </Box>

      <Box align="center" basis="1/3">
        <Heading
          textAlign="center"
          margin={{
            bottom: "xsmall",
          }}
          level="3"
          size="medium"
        >
          Want to solve that problem?
        </Heading>
        <Paragraph textAlign="center">
          Become an associate. With us, you’ll work on some of the most
          important and best problems to solve — and it’ll be fun.
        </Paragraph>
        <Link to="/join-us">
          <Button size="medium" color="brand" label="Get involved" />
        </Link>
      </Box>
    </Box>
  )
}

export default ClientHire
