import * as React from "react"

import { Box, Grid } from "grommet"
import {StaticImage} from "gatsby-plugin-image"

export default function ImageDistribution() {
  return (
    <Box fill pad={{ horizontal: "medium" }}>
      <Box margin={{ top: "xlarge", horizontal: "medium" }}>
        <Grid gap="small" margin={{ top: "medium" }} columns={["2/3", "flex"]}>
          <StaticImage
          alt="A service design workshop"
          src="../static/images/post-it.jpg"
          layout="fullWidth"
          />
          <Box gap="small">
            <StaticImage
              alt="Mapping out a service blueprint"
              src="../static/images/training-journey.png"
              layout="fullWidth"
            />
            <StaticImage
              alt="User using a mobile first service"
              src="../static/images/phone.jpg"
              layout="fullWidth"
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}
