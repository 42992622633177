import React from "react"
import { Link } from "gatsby"
import { Box, Heading, Button, Paragraph, Text } from "grommet"

import texture from "../static/images/texture.svg"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Clients from "../components/clients"
import ClientHire from "../components/clienthire"
import ImageDistribution from "../components/imagedistribution"
import SignUp from "../components/signup"
import JournalPosts from "../components/journalposts"
import { FormNextLink } from "grommet-icons"
import styled from "styled-components"

const ResponsiveHeading = styled(Heading)`
  @media (max-width: 400px) {
    font-size: 2.5em;
  }
`

export default function Home({ data, location }) {
  return (
    <Layout>
      <Seo title="Mindsets, service design and skills" />
      <Box
        alignSelf="center"
        width="xlarge"
        align="center"
        pad={{ horizontal: "medium" }}
      >
        <ResponsiveHeading
          textAlign="center"
          margin={{ top: "xlarge", bottom: "xsmall" }}
          level="1"
          size="large"
          style={{ lineHeight: "1em" }}
        >
          The&nbsp;economy is not&nbsp;fit for&nbsp;purpose
        </ResponsiveHeading>
        <Paragraph
          margin={{ bottom: "xsmall" }}
          textAlign="center"
          size="large"
        >
          People understand the need to be sustainable. It's how you do it
          that's the hard bit.
        </Paragraph>
        <Paragraph
          margin={{ top: "none", bottom: "medium" }}
          textAlign="center"
          size="large"
        >
          Po3 is a radical new design company giving you a competitive advantage
          in the circular economy.
        </Paragraph>
        <Text color="blue" margin="none" textAlign="center" size="xlarge">
          <Link color="blue" to="contact">
            <Button
              as="span"
              size="large"
              primary
              color="brand"
              label="Speak to the team"
              icon={<FormNextLink />}
            />
          </Link>
        </Text>
      </Box>
      <Box
        background={{
          color: "brand",
          dark: false,
          size: "cover",
          position: "center",
          repeat: "no-repeat",
          image: `url(${texture})`,
        }}
        height="auto"
        margin={{ top: "xlarge" }}
        pad={{ vertical: "xlarge", horizontal: "medium" }}
        justify="center"
      >
        <Box
          width="large"
          alignSelf="center"
          pad={{ vertical: "medium", horizontal: "medium" }}
        >
          <Heading
            color="white"
            margin={{ bottom: "none" }}
            level="2"
            size="large"
            textAlign="center"
          >
            Mindsets, service design and skills for the circular economy.
          </Heading>
          <Paragraph fill textAlign="center" size="large" color="white">
            We'll quickly take you <strong>from:</strong> thinking about
            sustainability and circular systems <strong>to:</strong> having a
            competitive advantage.
          </Paragraph>
        </Box>
        <Box
          direction="row-responsive"
          gap="medium"
          width="xlarge"
          alignSelf="center"
        >
          <Box
            background="light-1"
            basis="1/3"
            pad={{ vertical: "medium", horizontal: "medium" }}
            direction="row"
            round="xsmall"
            margin={{ vertical: "small" }}
          >
            <Box width="medium">
              <Heading
                level={3}
                margin={{ top: "small", bottom: "none" }}
                size="small"
              >
                Become a leader in the future economy
              </Heading>
              <Paragraph>
                Get hands on with the tools of the future in one day
                masterclasses
              </Paragraph>
              <Link to="/services/shape-mindsets">
                <Button
                  primary
                  color="brand"
                  size="medium"
                  label="Shape mindsets"
                />
              </Link>
            </Box>
          </Box>
          <Box
            background="light-1"
            basis="1/3"
            pad={{ vertical: "medium", horizontal: "medium" }}
            direction="row"
            round="xsmall"
            margin={{ vertical: "small" }}
          >
            <Box width="medium">
              <Heading
                level={3}
                margin={{ top: "small", bottom: "none" }}
                size="small"
              >
                Build a competitive advantage
              </Heading>
              <Paragraph>
                De-risk ideas and design cutting edge services in 60 days
              </Paragraph>
              <Link to="/services/design-services">
                <Button
                  primary
                  color="brand"
                  size="medium"
                  label="Design services"
                />
              </Link>
            </Box>
          </Box>
          <Box
            background="light-1"
            basis="1/3"
            pad={{ vertical: "medium", horizontal: "medium" }}
            direction="row"
            round="xsmall"
            margin={{ vertical: "small" }}
          >
            <Box width="medium">
              <Heading
                level={3}
                margin={{ top: "small", bottom: "none" }}
                size="small"
              >
                Get ready for the future of work
              </Heading>
              <Paragraph>
                Rapidly build new capabilities through our Academy programmes
              </Paragraph>
              <Link to="/services/empower-your-team">
                <Button
                  primary
                  color="brand"
                  size="medium"
                  label="Empower your teams"
                />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        alignSelf="center"
        align="center"
        pad={{ top: "large", horizontal: "medium" }}
        width="xlarge"
      >
        <ClientHire />
      </Box>
      <Box width="xlarge" alignSelf="center">
        <ImageDistribution />
      </Box>
      <Box>
        <Box
          width="large"
          alignSelf="center"
          align="center"
          pad={{ vertical: "xlarge", horizontal: "medium" }}
        >
          <Heading
            textAlign="center"
            margin={{ bottom: "none" }}
            level="1"
            size="medium"
          >
            People we've worked with
          </Heading>
          <Paragraph
            textAlign="center"
            margin={{ bottom: "large" }}
            size="large"
          >
            To solve systemic problems, you need a plurality of perspectives.
            We’ve worked across the public, private and charity sectors and we
            want to use that experience to create positive outcomes for all.
          </Paragraph>

          <Clients />
        </Box>
      </Box>

      <Box>
        <Box
          width="xlarge"
          alignSelf="center"
          align="center"
          pad={{ top: "large", bottom: "xlarge", horizontal: "medium" }}
        >
          <Heading
            textAlign="center"
            margin={{ top: "xlarge", bottom: "large" }}
            level="1"
            size="medium"
          >
            From the journal
          </Heading>
          <JournalPosts />
          <Link to="/journal">
            <Button
              margin={{ top: "medium" }}
              size="medium"
              color="brand"
              label="View the journal"
            />
          </Link>
        </Box>
      </Box>

      <Box background="background-contrast">
        <Box
          width="large"
          alignSelf="center"
          pad={{ vertical: "large", horizontal: "medium" }}
        >
          <Heading margin={{ bottom: "none" }} level="2" size="medium">
            Sign up for purpose
          </Heading>
          <Paragraph margin={{ bottom: "large" }} size="large">
            We're writing a book, a 'how-to' for the design and delivery of
            purpose driven, successful businesses.
            <br />
            Then <strong>we're giving it away, so anyone can use it</strong>.
          </Paragraph>
          <SignUp color="brand" />
        </Box>
      </Box>
    </Layout>
  )
}
